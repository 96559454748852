<template>
  <b-modal id="modal-add-fixing" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="PlusIcon" class="mr-05" />
        <span class="fs-18">Tambah Data Fixing</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="fixingDataRules">
          <b-form>
            <!-- scraper_name -->
            <b-form-group>
              <validation-provider #default="{ errors }" name="Scraper Name">
                <label for="scraper_name">
                  Nama Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group>
                  <b-input-group-prepend is-text style="margin-right: -5px;">
                    <feather-icon icon="GlobeIcon" />
                  </b-input-group-prepend>
                  <div class="scraper-dropdown" style="width: 27.1rem;">
                    <Dropdown
                      :options="scraper_name_options"
                      v-on:selected="scraperSelection"
                      :disabled="false"
                      :maxItem="10"
                      placeholder="Pilih Scraper"
                    />
                  </div>
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- problem classification -->
            <b-form-group>
              <validation-provider #default="{ errors }" name="Scraper Name">
                <label for="problem_classification">
                  Klasifikasi Masalah <span class="text-danger">*</span>
                </label>
                <b-input-group>
                  <b-input-group-prepend is-text style="margin-right: -5px;">
                    <feather-icon icon="FolderIcon" />
                  </b-input-group-prepend>
                  <div class="scraper-dropdown" style="width: 27.1rem;">
                    <Dropdown
                      :options="problem_options"
                      v-on:selected="classificationSelection"
                      :disabled="false"
                      :maxItem="10"
                      placeholder="Pilih Klasifikasi Masalah"
                    />
                  </div>
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- problem -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Masalah"
                rules="required"
              >
                <label for="masalah">
                  Masalah <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    v-model="fixing_data.message"
                    id="masalah"
                    placeholder="Masukkan Masalah"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- solution -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Solution"
                rules="required"
              >
                <label for="solution">
                  Solusi <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    v-model="fixing_data.solution"
                    id="solution"
                    placeholder="Masukkan Solusi"
                    type="solution"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelAddFixing"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        :disabled="
          !fixing_data.scraper_name || !fixing_data.problem_classification
        "
        @click.prevent="addFixingData"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Dropdown from "vue-simple-search-dropdown";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "addUser",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    Dropdown,
    BFormTextarea,
    "loader-component": floating_loader,
  },
  props: ["scraper_options", "problem_classification_options"],
  data() {
    return {
      scraper_name_options: this.scraper_options,
      problem_options: this.problem_classification_options,
      is_loading: false,
      username_available: false,
      fixing_data: {
        scraper_name: null,
        problem_classification: null,
        message: null,
        solution: null,
      },
      //   validation
      required,
    };
  },
  watch: {
    scraper_options() {
      this.scraper_name_options = this.scraper_options;
    },
    problem_classification_options() {
      this.problem_options = this.problem_classification_options;
    },
  },
  methods: {
    classificationSelection(data) {
      this.fixing_data.problem_classification = data.name;
    },
    scraperSelection(data) {
      this.fixing_data.scraper_name = data.name;
    },
    errorMessage(err) {
      const message =
        err &&
        "response" in err &&
        "data" in err.response &&
        err.response.data.detail
          ? err.response.data.detail.message ||
            "Terjadi Kesalahan Pada Aplikasi"
          : "Terjadi Kesalahan Pada Aplikasi";
      return message;
    },
    addFixingData() {
      this.$refs.fixingDataRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          this.username_available = false;
          let api = process.env.VUE_APP_API_URL + "fixing-scraper/save";
          useJwt.axiosIns
            .post(api, { data: this.fixing_data })
            .then(() => {
              this.fixing_data = {
                scraper_name: null,
                message: null,
                solution: null,
              };
              this.username_available = false;
              this.$bvModal.hide("modal-add-fixing");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Fixing Data berhasil ditambahkan`,
                },
              });
            })
            .catch((err) => {
              const message = this.errorMessage(err);
              this.$bvModal.hide("modal-add-fixing");
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal",
                  icon: "XCircleIcon",
                  variant: "danger",
                  text: message,
                },
              });
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelAddFixing() {
      this.fixing_data = {
        scraper_name: null,
        message: null,
        solution: null,
      };
      this.$bvModal.hide("modal-add-fixing");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
