var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-edit-fixing","size":"md","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-05",attrs:{"size":"20","icon":"EditIcon"}}),_c('span',{staticClass:"fs-18"},[_vm._v("Edit Data Fixing")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"float-right mr-05",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":_vm.cancelAddFixing}},[_vm._v(" Batal ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-info","size":"sm","disabled":!_vm.data.scraper_name},on:{"click":function($event){$event.preventDefault();return _vm.editFixingData($event)}}},[_vm._v(" Simpan ")])]},proxy:true}])},[(_vm.is_loading)?_c('loader-component'):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"fixingDataRules"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Scraper Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"scraper_name"}},[_vm._v(" Nama Scraper "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"masalah","placeholder":"Nama Scraper","disabled":""},model:{value:(_vm.data.scraper_name),callback:function ($$v) {_vm.$set(_vm.data, "scraper_name", $$v)},expression:"data.scraper_name"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Scraper Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"problem_classification"}},[_vm._v(" Klasifikasi Masalah "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"masalah","placeholder":"Klasifikasi Masalah","disabled":""},model:{value:(_vm.data.problem_classification),callback:function ($$v) {_vm.$set(_vm.data, "problem_classification", $$v)},expression:"data.problem_classification"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Masalah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"masalah"}},[_vm._v(" Masalah "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-textarea',{attrs:{"id":"masalah","placeholder":"Masukkan Masalah"},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Solution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"solution"}},[_vm._v(" Solusi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-textarea',{attrs:{"id":"solution","placeholder":"Masukkan Solusi","type":"solution"},model:{value:(_vm.data.solution),callback:function ($$v) {_vm.$set(_vm.data, "solution", $$v)},expression:"data.solution"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }