<template>
  <b-modal id="modal-edit-fixing" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="EditIcon" class="mr-05" />
        <span class="fs-18">Edit Data Fixing</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="fixingDataRules">
          <b-form>
            <!-- scraper_name -->
            <b-form-group>
              <validation-provider #default="{ errors }" name="Scraper Name">
                <label for="scraper_name">
                  Nama Scraper <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="data.scraper_name"
                    id="masalah"
                    placeholder="Nama Scraper"
                    disabled
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- problem_classification -->
            <b-form-group>
              <validation-provider #default="{ errors }" name="Scraper Name">
                <label for="problem_classification">
                  Klasifikasi Masalah <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="data.problem_classification"
                    id="masalah"
                    placeholder="Klasifikasi Masalah"
                    disabled
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- problem -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Masalah"
                rules="required"
              >
                <label for="masalah">
                  Masalah <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    v-model="data.message"
                    id="masalah"
                    placeholder="Masukkan Masalah"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- solution -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Solution"
                rules="required"
              >
                <label for="solution">
                  Solusi <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    v-model="data.solution"
                    id="solution"
                    placeholder="Masukkan Solusi"
                    type="solution"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelAddFixing"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        :disabled="!data.scraper_name"
        @click.prevent="editFixingData"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "editFixing",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    BFormTextarea,
    "loader-component": floating_loader,
  },
  props: {
    data: Object,
    scraper_options: Array,
  },
  data() {
    return {
      scraper_name_options: this.scraper_options,
      is_loading: false,
      username_available: false,
      //   validation
      required,
    };
  },
  watch: {
    scraper_options() {
      this.scraper_name_options = this.scraper_options;
    },
  },
  methods: {
    scraperSelection(data) {
      this.data.scraper_name = data.name;
    },
    editFixingData() {
      this.$refs.fixingDataRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          const {
            scraper_name,
            solution,
            problem_classification,
            message,
          } = this.data;
          const params = {
            scraper_name,
            solution,
            problem_classification,
            message,
          };
          let api =
            process.env.VUE_APP_API_URL +
            "fixing-scraper/edit/" +
            this.data._id;
          useJwt.axiosIns
            .put(api, { data: params })
            .then(() => {
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Fixing Data berhasil diubah`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal",
                  icon: "XCircleIcon",
                  variant: "error",
                  text: `Fixing Data gagal diubah`,
                },
              });
            })
            .finally(() => {
              this.is_loading = false;
              this.$bvModal.hide("modal-edit-fixing");
            });
        }
      });
    },
    cancelAddFixing() {
      this.$bvModal.hide("modal-edit-fixing");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
