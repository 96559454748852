<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="pagination.items"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      :current-page="pagination.page"
      sticky-header="700px"
      show-empty
      :tbody-tr-class="rowClass"
      @row-clicked="onRowClicked"
    >
      <!-- show empty data -->
      <template #empty>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- show empty filtered -->
      <template #emptyfiltered>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- custom cell no -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- custom cell updated_at -->
      <template #cell(updated_at)="data">
        <b-badge variant="light-success">{{ parseDate(data.value) }}</b-badge>
      </template>

      <!-- custom cell error_message -->
      <template #cell(error_message)="data">
        <div
          v-if="Array.isArray(data.value)"
          class="scroller"
          style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
        >
          <div
            v-for="(item, index) in data.value"
            :key="index"
            class="border rounded mb-1 p-1"
          >
            <div class="row">
              <div class="col-md-2 d-flex pr-0">
                URL <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.url }}</div>
              <div class="col-md-2 d-flex pr-0">
                Error <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.error }}</div>
              <div class="col-md-2 d-flex pr-0">
                Type <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.type }}</div>
              <div class="col-md-2 d-flex pr-0">
                Line <span class="ml-auto">:</span>
              </div>
              <div class="col-md-10">{{ item.line }}</div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="scroller"
          style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
        >
          {{
            data.value
              ? data.value.length > 200
                ? data.value.substring(0, 200) + "..."
                : data.value
              : "-"
          }}
        </div>
      </template>

      <!-- custom cell status -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.value.toLowerCase() == 'error'"
          pill
          variant="danger"
          style="width: 8rem !important"
        >
          Error
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'redirected'"
          pill
          variant="info"
          style="width: 8rem !important"
        >
          Redirected
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'unavailable'"
          pill
          variant="secondary"
          style="width: 8rem !important"
        >
          Unavailable
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'missingclass'"
          pill
          variant="warning"
          style="width: 8rem !important"
        >
          Missing Class
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'onprogress'"
          pill
          variant="light-warning"
          style="width: 8rem !important"
        >
          On Progress
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'running'"
          pill
          variant="primary"
          style="width: 8rem !important"
        >
          Running
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'fixed'"
          pill
          variant="success"
          style="width: 8rem !important"
        >
          Fixed
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'completed'"
          pill
          variant="success"
          style="width: 8rem !important"
        >
          Completed
        </b-badge>
        <b-badge v-else pill variant="secondary" style="width: 8rem !important">
          Open
        </b-badge>
      </template>

      <!-- custom cell status -->
      <template #cell(status_awal)="data">
        <b-badge
          v-if="data.value.toLowerCase() == 'error'"
          variant="danger"
          pill
          style="width: 8rem !important"
        >
          Error
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'redirected'"
          variant="info"
          pill
          style="width: 8rem !important"
        >
          Redirected
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'unavailable'"
          variant="secondary"
          pill
          style="width: 8rem !important"
        >
          Unavailable
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'missingclass'"
          variant="warning"
          pill
          style="width: 8rem !important"
        >
          Missing Class
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'completed'"
          variant="success"
          pill
          style="width: 8rem !important"
        >
          Completed
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'running'"
          variant="primary"
          pill
          style="width: 8rem !important"
        >
          Running
        </b-badge>
        <b-badge v-else variant="primary" pill style="width: 8rem !important">
          Fixing
        </b-badge>
      </template>

      <!-- custom cell start -->
      <template #cell(start)="data">
        <b-badge variant="light-success">{{ parseDate(data.value) }}</b-badge>
      </template>

      <!-- custom cell username -->
      <template #cell(username)="data">
        <div>{{ data.value || "-" }}</div>
      </template>

      <!-- custom cell scraper name -->
      <template #cell(scraper_name)="data">
        <div class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom problem_classification -->
      <template #cell(problem_classification)="data">
        <div v-if="data.value">
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell _id -->
      <template #cell(_id)="data">
        <div class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell end -->
      <template #cell(end)="data">
        <b-badge variant="light-danger">{{
          data.value ? parseDate(data.value) : "Still Running"
        }}</b-badge>
      </template>

      <!-- custom cell duration -->
      <template #cell(duration)="data">
        {{ data.value || 0 }}
      </template>

      <!-- custom cell count -->
      <template #cell(count)="data">
        {{ data.value || 0 }}
      </template>

      <!-- custom cell solution -->
      <template #cell(solution)="data">
        <div v-if="data.value" class="scroller" style="max-height: 10rem">
          {{
            data.value.length > 200
              ? data.value.substring(0, 200) + "..."
              : data.value
          }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell actions -->
      <template #cell(actions)="data">
        <div v-if="!data.item.status_awal" class="d-flex">
          <b-button
            @click="editItems(data.item._id)"
            variant="outline-info"
            class="btn-icon mr-05"
            size="sm"
            v-b-tooltip.hover.v-dark="'Edit'"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            @click="deleteItems(data.item._id, data.item.nama)"
            variant="outline-danger"
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="'Hapus'"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <div class="row mt-2">
      <!-- row information and select row -->
      <div class="col-md-5 col-sm-12">
        <div class="d-flex align-items-center float-left ml-2">
          <div>Showing {{ pagination.page }} to</div>
          <div class="ml-1 mr-1">
            <b-form-select
              v-model="pagination.items"
              :options="[5, 10, 25, 50, 100]"
              class="border-secondary"
            />
          </div>
          <div>
            of
            {{ pagination.count }} Entries
          </div>
        </div>
      </div>

      <!-- pagination -->
      <div class="col-md-7 col-sm-12">
        <div class="float-right mr-2">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.count"
            :per-page="pagination.items"
            size="md"
            class="pagination-info"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/helper/helper.js";
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
  BBadge,
  BFormInput,
} from "bootstrap-vue";

export default {
  name: "dataTable",
  components: {
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    VBTooltip,
    BBadge,
    BFormInput,
  },

  props: {
    is_row_custom_color: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    is_row_clicked: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        items: 10,
        count: 0,
      }),
    },
    data: Object,
    viewDetail: Function,
    editItems: Function,
    deleteItems: Function,
    onProgress: Function,
    fixedData: Function,
    editKeterangan: Function,
  },
  data() {
    return {
      is_copied: false,
      text_copied: null,
    };
  },
  methods: {
    rowClass(item, type) {
      if (this.is_row_custom_color) {
        if (item?.count == 0) {
          return "bg-light-danger";
        }
      }
    },
    copyToClipboard(text) {
      this.text_copied = text;
      navigator.clipboard.writeText(text).then(() => {
        this.is_copied = true;
        setTimeout(() => {
          this.is_copied = false;
          this.text_copied = null;
        }, 1000);
      });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    onRowClicked(item) {
      if (this.is_row_clicked) {
        this.viewDetail(item._id);
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
