var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-fixing","size":"md","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-05",attrs:{"size":"20","icon":"PlusIcon"}}),_c('span',{staticClass:"fs-18"},[_vm._v("Tambah Data Fixing")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"float-right mr-05",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":_vm.cancelAddFixing}},[_vm._v(" Batal ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-info","size":"sm","disabled":!_vm.fixing_data.scraper_name || !_vm.fixing_data.problem_classification},on:{"click":function($event){$event.preventDefault();return _vm.addFixingData($event)}}},[_vm._v(" Simpan ")])]},proxy:true}])},[(_vm.is_loading)?_c('loader-component'):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"fixingDataRules"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Scraper Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"scraper_name"}},[_vm._v(" Nama Scraper "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"margin-right":"-5px"},attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"GlobeIcon"}})],1),_c('div',{staticClass:"scraper-dropdown",staticStyle:{"width":"27.1rem"}},[_c('Dropdown',{attrs:{"options":_vm.scraper_name_options,"disabled":false,"maxItem":10,"placeholder":"Pilih Scraper"},on:{"selected":_vm.scraperSelection}})],1)],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Scraper Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"problem_classification"}},[_vm._v(" Klasifikasi Masalah "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"margin-right":"-5px"},attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"FolderIcon"}})],1),_c('div',{staticClass:"scraper-dropdown",staticStyle:{"width":"27.1rem"}},[_c('Dropdown',{attrs:{"options":_vm.problem_options,"disabled":false,"maxItem":10,"placeholder":"Pilih Klasifikasi Masalah"},on:{"selected":_vm.classificationSelection}})],1)],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Masalah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"masalah"}},[_vm._v(" Masalah "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-textarea',{attrs:{"id":"masalah","placeholder":"Masukkan Masalah"},model:{value:(_vm.fixing_data.message),callback:function ($$v) {_vm.$set(_vm.fixing_data, "message", $$v)},expression:"fixing_data.message"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Solution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"solution"}},[_vm._v(" Solusi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-textarea',{attrs:{"id":"solution","placeholder":"Masukkan Solusi","type":"solution"},model:{value:(_vm.fixing_data.solution),callback:function ($$v) {_vm.$set(_vm.fixing_data, "solution", $$v)},expression:"fixing_data.solution"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }